import { useContext, useEffect, useRef } from 'react';
import type {
  ElementType,
  FC,
  MutableRefObject,
  PropsWithChildren,
  ReactElement,
} from 'react';

import { ObserverContext } from './observer';

export type ObservedProps = {
  onEnter: () => void;
  onLeave: () => void;
  renderAs?: ElementType;
  className?: string;
};

export const Observed: FC<PropsWithChildren<ObservedProps>> = ({
  children,
  onEnter,
  onLeave,
  renderAs = 'div',
  className,
  ...props
}): ReactElement => {
  const meRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const context = useContext(ObserverContext);

  useEffect(() => {
    if (meRef.current && context.uID !== undefined) {
      const el: HTMLElement = meRef.current;
      el.id = context.uID();
      context.observeMe({ el, onEnter, onLeave });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meRef]);

  const Element = renderAs;

  return (
    <Element ref={meRef} className={className} {...props}>
      {children}
    </Element>
  );
};
