import * as React from 'react';

type ObserverArgs = {
  el: HTMLElement;
  onEnter: () => void;
  onLeave: () => void;
};

type Context = {
  observeMe: ({ el, onEnter, onLeave }: ObserverArgs) => void;
  uID: () => string;
  page: string;
};

type Observed = {
  el: HTMLElement;
  observed: boolean;
  viewed: boolean;
  onEnter: () => void;
  onLeave: () => void;
};

type ObservedValues = {
  el?: HTMLElement;
  observed?: boolean;
  viewed?: boolean;
  onEnter?: () => void;
  onLeave?: () => void;
};

type ObserverProps = {
  track?: React.RefObject<HTMLElement>;
  options?: IntersectionObserverInit;
  children?: React.ReactNode;
  /** page name used for operational tracking - defaults to 'unknown' */
  page?: string;
};

type Obj = Record<string, Observed>;

const Context = React.createContext({} as Context);

class Observer extends React.Component<ObserverProps, Obj> {
  idCount = 0; // UID generation
  state: Obj = {}; // observed refcount
  observer: IntersectionObserver | null = null; // IntersectionObserver instance

  setObserver(): void {
    const { props } = this;
    const options = props.options ?? {
      root: props.track?.current ?? null,
      rootMargin: '0px',
    };

    this.observer = new IntersectionObserver(this.observe, options);
  }

  componentDidUpdate(): void {
    const { state } = this;
    if (!this.observer) this.setObserver();

    Object.keys(state).forEach((k) => {
      if (!state[k].observed && this.observer) {
        this.observer.observe(state[k].el);
        this.updateNode(k, { observed: true });
      }
    });
  }

  componentWillUnmount(): void {
    if (this.observer) {
      this.observer.disconnect(); // unlisten all
      this.observer = null;
    }
  }

  updateNode = (id: string, value: ObservedValues): void => {
    const { state } = this;
    this.setState({
      ...{ [id]: { ...state[id], ...value } },
    });
  };

  observe = (entries: IntersectionObserverEntry[]): void => {
    entries.forEach((entry) => {
      const id = entry.target.getAttribute('id');
      if (id) {
        const node = this.state[id];
        // const ratio = entry.intersectionRatio;

        if (!node.viewed && entry.isIntersecting) {
          node.onEnter();
          this.updateNode(id, { viewed: true });
        }

        if (node.viewed && !entry.isIntersecting) {
          node.onLeave();
          this.updateNode(id, { viewed: false });
        }
      }
    });
  };

  observeMe = ({ el, onEnter, onLeave }: ObserverArgs): void => {
    const id = el.getAttribute('id');
    if (id) {
      this.updateNode(id, {
        el,
        onEnter,
        onLeave,
        observed: false,
        viewed: false,
      });
    }
  };

  uID = (): string => {
    const id = `observed-${this.props.page || 'unknown'}-${this.idCount}`;
    this.idCount++;
    return id;
  };

  render(): React.ReactNode {
    const { observeMe, uID } = this;
    const { children, track, page, ...props } = this.props;

    return (
      <Context.Provider value={{ observeMe, uID, page: page || 'unknown' }}>
        <div {...props}>{children}</div>
      </Context.Provider>
    );
  }
}

export { Observer, Context as ObserverContext };
